.product-pg-img-cntr {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.product-pg-img-cntr img {
  max-width: 700px;
  height: auto;
}

.prod-sub-img-cntr {
  position: relative;
  max-width: 700px;
  width: 100%;
}

.indications-true {
  height: 23%;
  width: 20%;
  z-index: 1;
  top: 34%;
  position: absolute;
}

.indications-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 34%;
  position: absolute;
}

.dosage-true {
  height: 23%;
  width: 20%;
  z-index: 1;
  bottom: 17%;
  position: absolute;
  left: 3%;
}

.dosage-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  bottom: 17%;
  position: absolute;
  left: 3%;
}

.action-true {
  height: 23%;
  width: 20%;
  z-index: 1;
  top: 33%;
  right: 1%;
  position: absolute;
}

.action-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 33%;
  right: 1%;
  position: absolute;
}

.FAQ-true {
  height: 23%;
  width: 20%;
  z-index: 1;
  bottom: 17%;
  position: absolute;
  right: 4%;
}

.FAQ-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  bottom: 17%;
  position: absolute;
  right: 4%;
}

.side_effects-true {
  height: 23%;
  width: 20%;
  z-index: 1;
  top: 22%;
  position: absolute;
  left: 22%;
}

.side_effects-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 22%;
  position: absolute;
  left: 22%;
}

.interaction-true {
  height: 23%;
  width: 20%;
  z-index: 1;
  top: 22%;
  position: absolute;
  right: 22%;
}

.interaction-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 22%;
  position: absolute;
  right: 22%;
}

.safety_advice-true {
  height: 23%;
  width: 20%;
  z-index: 1;
  top: 7%;
  position: absolute;
  right: 40%;
}

.safety_advice-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 7%;
  position: absolute;
  right: 40%;
}

.select-batch {
  display: grid;
  place-items: center;
}

.select-batch button {
  background-color: #c82f78;
  color: #ffff;
  border: 1px solid #c82f78;
  border-radius: 25px;
  box-shadow: 1px 3px 5px 1px #15151568;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
}

.select-batch button:hover {
  background-color: white;
  color: #c82f78;
}

.batch-img {
  height: fit-content !important;
  width: 70px !important;
  margin-bottom: 1rem !important;
  cursor: pointer;
}

.batch-img img {
  border-radius: 16px;
  box-shadow: 1px 3px 5px 1px #15151568;
  transition: 0.25s ease;
  filter: saturate(2);
}

.batch-img img:hover {
  filter: sepia(1);
  transform: scale(1.1);
  box-shadow: 1px 3px 5px 1px #15151568;
}

.fabButton {
  position: absolute !important;
  z-index: 1 !important;
  top: -34px !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important;
  background: #4dc85c !important;
  border: 1px solid #4dc85c !important;
  display: grid;
  place-items: center;
}

.fabButton img {
  max-width: 60%;
}
.batch_select {
  font-size: 1.5rem !important;
  width: fit-content !important;
  padding-top: 16px !important;
  margin: 0px 10px;
}
.product_name {
  text-align: left;
  color: #444a9c;
  font-size: 3.2rem;
}
.product_salt {
  text-align: left;
  color: #e06287 !important;
  font-size: 2.1rem;
  padding-bottom: 15px;
  margin-top: 0px;
  word-spacing: 2px;
  line-height: 24px !important;
}

.product_main_data {
  width: 50% !important;
  padding: 0% 10%;
  margin: 0px 10px;
  line-height: 10px !important;
  display: flex;
  align-items: center;
}
.color_pink {
  color: #e04088 !important;
}
.color_primary {
  color: #4b4495 !important;
}
.about_product {
  padding: 10px;
}
.product_img {
  width: 338px;
  height: 278px;
}
.product_section_1 {
  justify-content: center !important;
  width: 100% !important;
  background-color: #f5f6f7;
  border-top: 1px solid #e3e5eb;
  border-bottom: 1px solid #e3e5eb;
}

.font_18 {
  font-size: 18px;
}
.font_16 {
  font-size: 16px;
}
.color_black {
  color: rgb(53, 52, 52) !important;
}
.color_dark {
  color: rgb(14, 13, 13);
}
.faqs {
  padding: 10px 5%;
}
.faq_header {
  color: #495062;
  font-size: 18px;
  font-weight: bolder;
  margin-top: -10px;
  margin-left: 30px;
}
.faq_accordion {
  border-left: 17px solid #e6eaeb;
  border-radius: 0px !important;
  padding: 0px !important;
}
.faq_parent {
  background-color: #f5f6f7;
  padding-bottom: 20px;
}

.qlty_rprts {
  margin-left: -10px;
  font-weight: bolder;
  color: #4b4495;
  line-height: 20px;
}
.qlty_rprts:hover {
  color: #e04088;
}
.product_section_2 {
  text-align: justify !important;
  padding: 3% 10px 0% 10px;
}
.font_2rem {
  font-size: 2.2rem !important;
}
.text_justify {
  text-align: justify !important;
}

.content_list {
  color: #495062;
  line-height: 35px;
  font-weight: bolder;
}
.sideEffects_sec {
  display: flex;
  flex-wrap: wrap !important;
}
.safety {
  width: 60%;
  margin-right: 10%;
}
.side_effects {
  width: 30%;
  padding-top: 2px !important;
}
.product_page_icons {
  width: 24px;
  height: 19px;
  margin-top: -3px;
}

.nav_link_icons {
  color: #a1a9be;
  margin: 0px !important;
  padding: 0px !important;
  font-size: 28px !important;
}

.product_nav_links {
  display: flex;
  align-items: center;
  padding: 15px 0px;
}

.batch-cntr {
  background-color: #ffffff;
  margin: 1rem;
  padding: 0 1rem;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 50%;
  height: 90vh;
  animation: openModal 0.25s forwards ease-in;
}

.batch-sub-cntr {
  padding: 1rem 0;
  height: 90%;
  overflow: scroll;
}

.batch-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.batch-header h3 {
  margin: 0;
  font-size: 2rem;
}

.batch-header p {
  color: #e06287 !important;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.batch-header p:hover {
  color: #c82f78 !important;
}

.batch-cntr h5 {
  height: 100%;
  display: grid;
  place-items: center;
  /* font-size: 0.9rem; */
  color: #01aeab;
  font-weight: 400;
  min-width: 250px;
  opacity: 0.7;
}

.uniqueId-cntr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.uniqueId-cntr input {
  width: 30px;
  height: fit-content;
  font-weight: 500;
  border: none;
  border-bottom: 1.5px solid #e06287;
  border-radius: 0;
  text-align: center;
  outline: #e06287;
  color: #e06287;
  text-transform: uppercase;
  padding: 0;
}

.uniqueId-cntr p {
  margin: 0;
  color: #a1a9be !important;
  font-weight: 400;
}

.uid-info {
  color: #495062 !important;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
  margin: 0;
}

.uid-power {
  color: #495062 !important;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  font-style: italic;
}

.uid-sample {
  letter-spacing: 10px;
  font-size: 1.3rem;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0.25rem 1rem;
  border-radius: 25px;
  background-color: #eff0fb;
  pointer-events: none;
}

.uid-sample span {
  letter-spacing: normal;
  margin-right: 1px;
  font-size: 1.2rem;
}

.uid-sample b {
  letter-spacing: normal;
  text-decoration: underline;
  text-align: center;
}

.uid-sample b:not(:last-child) {
  margin: 0 10px;
}

.ver-pass {
  color: #28a745 !important;
  font-size: 1.6rem !important;
  font-weight: 400;
  text-align: center;
}

.ver-fail {
  color: #dc3545 !important;
  font-size: 1.6rem !important;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.div-cntr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.react-pdf__Page:not(:last-child) {
  margin-bottom: 1rem;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}

.batch-cta-cntr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.batch-cta-cntr button {
  margin: 1rem 0;
  padding: 0.75rem;
  width: fit-content;
  background-color: #4b4495;
  color: #ffffff;
  border: 1px solid #4b4495;
  border-radius: 3px;
  font-weight: 400;
  font-size: 1.4rem;
  transition: all 0.25s ease-in-out;
}

.batch-cta-cntr button:hover {
  background-color: #c82f78;
  border-color: #c82f78;
  transform: scale(1.1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.096), 0px 0px 6px rgba(0, 0, 0, 0.13);
}

@media only screen and (max-width: 750px) {
  .product_main_data {
    width: 100% !important;
    text-align: center;
  }
  .safety {
    width: 100%;
  }
  .side_effects {
    width: 100%;
  }
  .product_name {
    font-size: 17px;
    text-align: center;
  }
  .product_salt {
    font-size: 16px;
    text-align: center;
  }
  .product_title_div {
    width: 100%;
    padding-top: 25px;
  }

  .salt_icon {
    width: 21px;
    height: 21px;
  }
  .product_page_icons {
    width: 22px;
    height: 22px;
  }
  .faq_header {
    margin-top: 10px;
    margin-left: 10px;
  }
  .faq_que {
    font-size: 14px !important;
    line-height: 20px;
    color: #444a9c !important;
  }
  .faq_ans {
    font-size: 14px !important;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .product_main_data {
    width: 100% !important;
  }
  .product_name {
    text-align: center;
  }
  .product_salt {
    text-align: center;
  }
  .product_title_div {
    width: 100%;
    padding-top: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .batch-cntr {
    height: 60vh;
  }
}
