.sitemap-wrapper h2 {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.sitemap-subPages-container {
  display: grid;
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.sitemap-pages {
  padding: 0 !important;
}

.sitemap-pages > li {
  list-style: none;
}

.sitemap-pages > li > a {
  text-decoration: none;
  text-transform: uppercase;
}

.sitemap-pages > li > a > h5 {
  color: #de6d93 !important;
  background-color: #efebf1 !important;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0.5rem;
  transition: all 0.25s ease;
}

.sitemap-pages > li > a > h5:hover {
  border-color: #de6d93;
}

.sitemap-subPages {
  padding-inline-start: 0.5rem;
}

.sitemap-subPages > li {
  list-style: none;
}

.sitemap-subPages > li > a > h6 {
  font-size: 1.4rem;
  color: #4b4495 !important;
  margin-bottom: 0.1rem;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  transition: all 0.25s ease;
}

.sitemap-subPages > li > a > h6:hover {
  border-bottom: 2px solid #4b4495;
}

.sitemap-products {
  padding-left: 10px;
}

.sitemap-products > li {
  list-style: none;
}

.sitemap-products > li::before {
  content: "‣";
  color: #706f6f;
  padding-right: 1rem;
  transition: all 0.25s ease;
}

.sitemap-products > li:hover::before {
  padding-right: 1.5rem;
}

.sitemap-products > li > a {
  font-size: 1.4rem;
  color: #706f6f;
}

@media (min-width: 600px) {
  .sitemap-subPages-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .sitemap-subPages-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
