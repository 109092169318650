.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inp-qa {
  display: flex;
  justify-content: space-between;
}

.inp-qa > input {
  border: none;
  outline: none;
}

.inp-qa > input[type="file"] {
  display: none;
}

.inp-qa > label {
  cursor: pointer;
  padding: 1rem 5rem;
  background-color: white;
  border: 2px solid #4b4495;
  border-radius: 3px;
  color: #4b4495;
  transition: 0.5s ease;
  font-size: 16px;
  max-width: 150px;
  transition: 0.5s ease;
}

.inp-qa > label:hover {
  background: #f5f4ff;
}

.inp-qa > span {
  padding: 1rem;
}

.inp-qa > a {
  flex: 1;
  background-color: white;
  border: 2px solid #4b4495;
  border-radius: 3px;
  padding: 1rem 5rem;
  margin-right: 1rem;
  height: 50px;
  text-align: center;
}

.inp-qa > input[type="submit"] {
  cursor: pointer;
  padding: 1rem 5rem;
  background-color: white;
  border: 2px solid #d30e36;
  border-radius: 3px;
  color: #d30e36;
  transition: 0.5s ease;
  font-size: 16px;
  max-width: 150px;
  transition: 0.5s ease;
}

.inp-qa > input[type="submit"]:hover {
  background-color: #fdd7df;
}

.qaBox {
  padding: 0 15px;
  border-radius: 3px;
}

.qaBox > p {
  color: #de6d93 !important;
  padding: 1rem;
  border: 2px solid #e7eaeb;
  text-align: center;
  transition: 0.5s ease;
  animation: flashPW 5s infinite forwards;
}
.about_home{
  /* width: 90%;
  margin: 0px auto; */
  border: 1px solid rgba(128, 128, 128, 0.171);
  border-radius: 10px;
}
.offering_img{
  width: 200px !important;
  
}
.display_flex{
  display: flex;
  flex-wrap: wrap;
}
.offering_img{
  width: 350px !important;
}
.offering_product_title{
  transition:all 0.2s ease-out;

}
.offering_product_title:hover{
  font-size: 28px;
  text-decoration: underline;
}
@keyframes flashPW {
  0% {
    opacity: 1;
    background-color: transparent;
  }

  25% {
    opacity: 0.3;
    background-color: #f5f4ff;
  }

  50% {
    opacity: 1;
    background-color: transparent;
  }

  75% {
    opacity: 0.3;
    background-color: #f5f4ff;
  }

  100% {
    opacity: 1;
    background-color: transparent;
  }
}
