body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.modal-cntr {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #00000055;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 1rem;
  backdrop-filter: blur(5px);
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}

@keyframes openModal {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
  }
}
